import React from 'react'
import facebook1 from '../assets/facebook1.png'
import instagram from '../assets/instagram.png'
import twitter from '../assets/twitter1.png'
import youtube from '../assets/youtube1.png'

function Footer() {
  return (
    <>
    <div className='footer py-2 bg-dark'>
    <div className='container'>
     <div className='row text-white py-3'>
     <div className='col-sm-4'>
     Address: NGO’s Colony, Narsampet , Warangal district – 5016132
     </div>
     <div className='col-sm-4'>
     
     </div>
     <div className='col-sm-4'>
       <h6>Stay Connected With Us </h6>
       
      <a href='#' className='px-2'><img src={facebook1} /></a>
      <a href='#' className='px-2'><img src={instagram} /></a>
      <a href='#' className='px-2'><img src={twitter} /></a>
      <a href='#' className='px-2'><img src={youtube} /></a>
     
     </div>
     </div>
     </div>
     <div className='ftb border-top bg-dark'>
      <div className='container'>
        <div className='row text-white pt-1'>
          <div className='col-sm-8'>
            <h6> &copy; Copyright Sri Veerabrahmendra Swamy Temple Trust</h6>
          </div>
          <div className='col-sm-4'>
            <small>Designed and Developed by Sai Kaushik</small>
          </div>
        </div>
      </div>
    </div>
    </div>
    
    
    </>
  )
}

export default Footer