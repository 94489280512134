import React from 'react'
import facebook1 from '../assets/facebook1.png'
import instagram from '../assets/instagram.png'
import twitter from '../assets/twitter1.png'
import youtube from '../assets/youtube1.png'
import om from '../assets/om.avif'
import images from '../assets/images.jpeg'
import veer from '../assets/2.jpg'
import om2 from '../assets/Om2.svg.png'

function Header() {
  return (
    <>
      <div className='bgtop py-2'>
     <div className='container-fluid'>
     <div className='row'>
     <div className='col-sm-4 text-white'>
      Contact: 9441681380
     </div>
     <div className='col-sm-4 text-white'>
      Temple Timings: 6:30 AM to 11 AM, 6 PM to 8 PM
     </div>
     <div className='col-sm-4 float-end'>
      <a href='#' className='px-2'><img src={facebook1} /></a>
      <a href='#' className='px-2'><img src={instagram} /></a>
      <a href='#' className='px-2'><img src={twitter} /></a>
      <a href='#' className='px-2'><img src={youtube} /></a>
     </div>
     </div>
     </div>
     </div>
     <div className='bghead'>
     <div className='container-fluid py-1'>
      <div className='row align-items-center justify-content-between'>
        <div className='col-sm-3'>
         <img src={images} style={{width:255,height:105}} alt="logo"/>
        </div>
        <div className='col-sm-6 text-center'>
          <h4>Sri Sri VeeraBrahmendraSwamy Devasthanam</h4>
          <h4 className='fw-bold'>శ్రీ శ్రీ వీరబ్రహ్మేంద్రస్వామి దేవస్థానం</h4>
         
        </div>
        <div className='col-sm-3'>
          <img src={om2} style={{width:100,height:105}} alt="Om Symbol"/>
        </div>
      </div>
     </div>
     </div>
     
      
      
      </>
  )
}

export default Header