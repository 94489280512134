import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter,Link,Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Donate from './pages/Donate';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
     <Header/>
     <Navbar expand="lg" className="bgtop py-0">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className='px-5 fw-bold text-white'>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className='px-5 fw-bold text-white'>About</Nav.Link>
            <Nav.Link as={Link} to="/gallery" className='px-5 fw-bold text-white'>Gallery</Nav.Link>
            <Nav.Link as={Link} to="/contact" className='px-5 fw-bold text-white'>Contact</Nav.Link>
            <Nav.Link as={Link} to="/donate" className='px-5 fw-bold text-white'>Donate</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>} />
      <Route path='/gallery' element={<Gallery/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/donate' element={<Donate/>} />
    </Routes>
      <Footer/>
    </div>
    </BrowserRouter>

  );
}

export default App;
