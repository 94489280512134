import React from 'react'

function About() {
  return (
    <div>

<div className='container py-3'>
  <div className='row'>
  <div className='col-sm-12'>
     <p className='jus'>This temple Sri Veera Brahmedra Swamy Temple was established by Late Valaboju Chandra Mouly garu, to help create a divine place for the community, he purchased a land measuring 1126 square yards in his name Valaboju Chandra Mouly. As per the directions of Warangal Endowment department he donated this land as gift deed to proceed further. </p>
   <p className='jus'>  To get the sanction for Common Good Fund (CGF), an amount of Rupees 64,000/- was deposited in Andhra Bank. Sri Veerabrahndra Sway temple was registered vide no 40/Act 30-1997, 22-2-1995 is registered on Managing Trustee as Late V. Chandra Mouly s/o Valaboju Ramaiah. As per  the letter dated issued on 5-7-2003, vide no 3/47581, as managing trustee he kept the temple under his custody. Endowment department has provided a grant of Rs 3,30,000/- for the development of temple. </p>
<p className='jus'>The temple has divine gods of Sri Brahmendraa Swamy, Smt Govindmaba, Bhakta Siddappa, Sri Bhakta Anjaneya Swamy , Shivalingam brought from Kasi were installed on 11-05-2023.</p>

<p className='jus'><strong>Founder Trustee responsibilities:</strong></p>
<p className='jus'>1.	After my demise founder trustee is given to my heirs.</p>
<p className='jus'>2.	Executive committee tenure is valid for 2 years or otherwise deemed to be continuing.</p>
<p className='jus'>3.	Any irregularities and misuse of funds by anyone can be terminated from the committee.</p>
<p className='jus'>4.	No one has any right to change the committee.</p>
<p className='jus'>5.	Only Managing Trustee can be dissolve the committee as per the Trust Act 1982.</p>
<p className='jus'><strong>Responsibilities of committee:</strong></p>
<p className='jus'>1.	Appointment of Archaka staff is conducted as per the directions of Trust.</p>
<p className='jus'>2.	Expenses as given by the government to be used for purpose of Dhoopam, Deepa, Naivedyam, Pooja samgri etc.</p>
<p className='jus'>3.	Every donation received by the committee member, a receipt must be given to the donor immediately.</p>


</div>
</div>
</div>

    </div>
  )
}

export default About