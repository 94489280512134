import React from 'react'

function Donate() {
  return (
    <>
    <div className='container py-3'>
      <div className='row'>
        <div className='col-sm-12'>
          <h4>Please find the QR Code below to Donate for Temple Development</h4>
        </div>
      </div>
    </div>
    
    </>
  )
}

export default Donate