import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import b2 from '../assets/b2.png'
import b3 from '../assets/b3.png'
import b4 from '../assets/b4.png'
import b5 from '../assets/b5.png'
import b6 from '../assets/b6.png'
import b7 from '../assets/b7.png'
import b8 from '../assets/b8.png'
import b9 from '../assets/b9.png'
import b10 from '../assets/b10.png'
import b11 from '../assets/b11.png'
import b12 from '../assets/b12.png'
import b13 from '../assets/b13.png'
import b14 from '../assets/b14.png'
import b15 from '../assets/b15.png'
import b16 from '../assets/b16.png'
import veer2 from '../assets/veer2.png'


function Gallery() {
  return (
    <>
    <h4 className='titlecolor py-2'>IMAGE GALLERY</h4>


    <Carousel>
      <Carousel.Item>
        <img src={b2}  />
      </Carousel.Item>
      {/* <Carousel.Item>
        <img src={b3} />
      </Carousel.Item> */}
      <Carousel.Item>
        <img src={b4} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b5} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b6} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b7} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b8} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b9} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b10} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b11} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b12} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b13} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b14} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b15} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={b16} />
      </Carousel.Item>
      <Carousel.Item>
        <img src={veer2} />
      </Carousel.Item>
    </Carousel>
    
    
    
    
    
    
    
    </>
  )
}

export default Gallery