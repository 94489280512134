import React from 'react'

function Contact() {
  return (
    <>
    <div className='container'>
      <div className='row py-3'>
        <div className='col-sm-4'>
          <div className='card'>
            <div className='card-body'>
              <p>Chairman</p>
            </div>
          </div>
         </div>
         <div className='col-sm-4'>
          <div className='card'>
            <div className='card-body'>
              <p>Vice Chairman</p>
            </div>
          </div>
         </div>
         <div className='col-sm-4'>
          <div className='card'>
            <div className='card-body'>
              <p>Pujari</p>
            </div>
          </div>
         </div>
      </div>
    </div>

    <div className='container'>
      <div className='row'>
        <div className='col-sm-12'>
          <h4 className='titlecolor'>Temple Address:</h4>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3795.92311035422!2d79.89213157142056!3d17.93573984933518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3365187fd33bed%3A0x88affbd18e6c419e!2sSri%20Sri%20Potuloori%20Veerabrhamendra%20Swamy%20Temple!5e0!3m2!1sen!2sin!4v1697267689447!5m2!1sen!2sin" style={{width:600,height:450}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
    
    
    </>
  )
}

export default Contact